import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission";
import "amfe-flexible";
import "./icons";
import Vant from "vant";
import "vant/lib/index.css";
import "./utils/vConsole";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { Base64 } from "js-base64";
import Storage from 'vue-ls'
import { Locale } from 'vant'
import enUS from 'vant/lib/locale/lang/en-US';
import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo);

Locale.use('en', enUS);

Vue.use(Antd);
Vue.use(Vant);
Vue.use(Base64);
let storageOptions = {
  namespace: 'pro__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
}
// "postcss-pxtorem": "^5.0.0",
Vue.use(Storage, storageOptions)

Vue.config.productionTip = false;
Vue.prototype.$Base64 = Base64;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
