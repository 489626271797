<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  computed: {},
};
</script>

<style lang="less">
@media screen and (min-width: 700px) {
  #app {
    width: 390px !important;
  }
  .foodModel {
    width: 390px !important;
    left: auto !important;
  }
  .van-overlay {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-popup--bottom {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-popup--top {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-sticky--fixed {
    width: 390px !important;
    left: calc(50% - 195px) !important;
  }
  .van-toast--html{
  width: 300px !important;
}
  
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
