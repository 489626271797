/*
 * @Author: NinJa 625379797@qq.com
 * @Date: 2023-08-30 11:48:08
 * @LastEditors: NinJa 625379797@qq.com
 * @LastEditTime: 2023-08-30 13:06:39
 * @FilePath: /QrCode/src/router/index.js
 * @Description: 
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    // 配置默认路由
    path: "/", // 路由地址
    redirect: "/qrCode", // 重定向
  },
  {
    path: "/MeetingHome",
    name: "MeetingHome",
    component: () => import("../views/MeetingHome"),
    meta: { title: "Hotel Service" }
  },
  {
    path: "/qrCode",
    name: "Home",
    component: Home,
    meta: { title: "Hotel Service" },
  },
  {
    path: "/Amenities",
    name: "Amenity",
    component: () => import("../views/Amenity"),
    meta: { title: "Hotel Service" }
  },
  {
    path: "/MeetingService",
    name: "MeetingService",
    component: () => import("../views/MeetingService"),
    meta: { title: "Hotel Service" }
  },
  {
    path: "/Services",
    name: "Service",
    component: () => import("../views/Service"),
    meta: { title: "Hotel Service" }
  },
  {
    path: "/Maintenance",
    name: "Maintenance",
    component: () => import("../views/Maintenance"),
    meta: { title: "Hotel Service" }
  },
  {
    path: "/FacilitiesAndHours",
    name: "Information",
    component: () => import("../views/Information"),
    meta: { title: "Hotel Service" }
  },
  {
    path: "/pdfView",
    name: "PdfView",
    component: () => import("../views/PdfView"),
    meta: { title: "Hotel Service" }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: { title: "Hotel Service" },
  },
  {
    path: "/404",
    name: "Page404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 };
  // },
});

export default router;
